module.exports = [{
      plugin: require('/zeit/76c09cda/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"monkey-mind-adventure","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/logo_gold-black.png"},
    },{
      plugin: require('/zeit/76c09cda/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-155138233-1"},
    },{
      plugin: require('/zeit/76c09cda/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
